import React, { Suspense, lazy, useEffect, useState } from "react";
import { Router, Routes, Route, useParams, HashRouter } from "react-router-dom";
import { hot } from "react-hot-loader";
import API from "./helpers/API";
import { useAuth } from "./Context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { AdminRoute } from "./routes/AdminRoute";
import { ApplicantRoute } from "./routes/ApplicantRoute";

const EmailVerification = lazy(
  () => import("./features/Account/EmailConfirmation/EmailVerification")
);
const Home = lazy(() => import("./features/Home/Home"));
const AboutUs = lazy(() => import("./features/About/AboutUs"));
const OurMission = lazy(() => import("./features/OurMission/OurMission"));
const OurVision = lazy(() => import("./features/OurVision/OurVision"));
const OurCoreServices = lazy(
  () => import("./features/TBSCoreServices/OurCoreServices")
);
const ITInfrastructure = lazy(
  () => import("./features/ITInfrastructure/ITInfrastructure")
);
const BehavioralHealth = lazy(
  () => import("./features/BehavioralHealth/BehavioralHealth")
);
const Education = lazy(() => import("./features/Education/Education"));
const HealthBillingCollection = lazy(
  () => import("./features/HealthBillingCollections/HealthBillingCollections")
);
const HealthcareMergersAndAcquisitions = lazy(
  () => import("./features/M&A/HealthcareM&A")
);
const RecruitingAndLeadership = lazy(
  () => import("./features/Recruiting&Leadership/Recruiting&Leadership")
);
const BehavioralHealthCredentialingAndContracting = lazy(
  () =>
    import(
      "./features/BehavioralHealthCredentialing&Contracting/BehavioralHealthCredentialing&Contracting"
    )
);
const WebDevelopmentServices = lazy(
  () => import("./features/WebDevelopmentServices/WebDevelopmentServices")
);
const DataAnalytics = lazy(
  () => import("./features/DataAnalytics/DataAnalytics")
);
const HigherEducation = lazy(
  () => import("./features/HigherEducation/HigherEducation")
);
const EducationCounselling = lazy(
  () => import("./features/EducationCounselling/EducationCounselling")
);
const Infrastructure = lazy(
  () => import("./features/InfrastructureServices/Infrastructure")
);
const Employers = lazy(() => import("./features/Employers/Employers"));
const ApplyJobForm = lazy(() => import("./features/Account/ApplyJobForm"));
const ApplyNonITJobForm = lazy(
  () => import("./features/Account/ApplyNonITJobForm")
);
const BrowseJobs = lazy(() => import("./features/Jobs/BrowseJobs"));
const NonITJobs = lazy(() => import("./features/Jobs/NonITJobs"));
const JobDetails = lazy(() => import("./features/Jobs/JobDetails"));
const NonITJobDetails = lazy(() => import("./features/Jobs/NonItJobDetails"));
const SearchJobFilter = lazy(() => import("./features/Jobs/SearchJobFilter"));
const ContactUs = lazy(() => import("./features/Contact/ContactUs"));
const ThankYou = lazy(() => import("./features/Contact/ThankYou"));
const ResumeUpload = lazy(() => import("./features/ResumeUpload/ResumeUpload"));
const Login = lazy(() => import("./features/Account/Login/Login"));
const Register = lazy(() => import("./features/Account/Register/Register"));

const ForgotPassword = lazy(
  () => import("./features/Account/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./features/Account/ForgotPassword/ResetPassword")
);

const AdminDashboardHome = lazy(() => import("./admin/Home"));
const AddCountry = lazy(() => import("./admin/Countries/AddCountry"));
const ManageCountries = lazy(() => import("./admin/Countries/ManageCountries"));
const AddSalary = lazy(() => import("./admin/Salaries/AddSalary"));
const ManageSalaries = lazy(() => import("./admin/Salaries/ManageSalaries"));
const AddProvince = lazy(() => import("./admin/Provinces/AddProvince"));
const ManageProvinces = lazy(() => import("./admin/Provinces/ManageProvinces"));
const AddSector = lazy(() => import("./admin//Sectors/AddSector"));
const ManageSectors = lazy(() => import("./admin/Sectors/ManageSectors"));
const AddQualification = lazy(
  () => import("./admin/Qualifications/AddQualification")
);
const ManageQualifications = lazy(
  () => import("./admin/Qualifications/ManageQualifications")
);
const AddWorkExperience = lazy(
  () => import("./admin/WorkExperiences/AddWorkExperience")
);
const ManageWorkExperiences = lazy(
  () => import("./admin/WorkExperiences/ManageWorkExperiences")
);
const AddWorkMode = lazy(() => import("./admin/WorkModes/AddWorkMode"));
const ManageWorkModes = lazy(() => import("./admin/WorkModes/ManageWorkModes"));
const AddWorkAuthorization = lazy(
  () => import("./admin/WorkAuthorizations/AddWorkAuthorization")
);
const ManageWorkAuthorizations = lazy(
  () => import("./admin/WorkAuthorizations/ManageWorkAuthorizations")
);
const PostJob = lazy(() => import("./admin/Jobs/PostJob"));
const PostNonITJob = lazy(() => import("./admin/Jobs/PostNonITJob"));
const ManageJobs = lazy(() => import("./admin/Jobs/ManageJobs"));
const ManageNonITJobs = lazy(() => import("./admin/Jobs/ManageNonITJobs"));
const DeleteJob = lazy(() => import("./admin/Jobs/DeleteJob"));
const UpdateJob = lazy(() => import("./admin/Jobs/UpdateJob"));

const ManageContactMessages = lazy(
  () => import("./admin/ContactMessages/ManageContactMessages")
);
const ViewContactMessage = lazy(
  () => import("./admin/ContactMessages/ViewContactMessage")
);

const DeleteContactMessage = lazy(
  () => import("./admin//ContactMessages/DeleteContactMessage")
);

const AdminChangePassword = lazy(
  () => import("./admin/ChangePassword/ChangePassword")
);
const Subscribers = lazy(() => import("./admin//Subscribers/Subscribers"));

const ApplicantDashboardHome = lazy(() => import("./applicant/Home"));
const ApplicantProfile = lazy(
  () => import("./applicant/ApplicantProfiles/ApplicantProfile")
);
const ApplicantAppliedJobs = lazy(
  () => import("./applicant/Jobs/ApplicantAppliedJobs")
);
const ApplicantChangePassword = lazy(
  () => import("./applicant/ChangePassword/ChangePassword")
);
const DeleteEducation = lazy(
  () => import("./applicant//Education/DeleteEducation")
);
const DeleteApplicantExperience = lazy(
  () => import("./applicant/Experience/DeleteApplicatExperience")
);
const DeleteSkill = lazy(() => import("./applicant/Skill/DeleteSkill"));

// Delete User Resume from Applicant Dashboard
const DeleteResume = lazy(() => import("./applicant/Resume/DeleteResume"));

// UnAuthorized Page
const UnAuthorized = lazy(() => import("./features/UnAuthorized/UnAuthorized"));

// Unsubscribe
const Unsubscribe = lazy(() => import("./features/Unsubscribe/Unsubscribe"));
const Loader = () => (
  <div className="container text-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);
const StyledContainer = styled(ToastContainer)`
        &&&.Toastify__toast-container {
        }
        .Toastify__toast {
        width: 550px !important;
        height: 200px;
        font-family: Trebuchet-ms;
        font-weight: bold;
        }
        .Toastify__toast-body {
        width: 550px !important;
        height: 200px
        font-family: Trebuchet-ms;
        }
        .Toastify__progress-bar {    
        }
  `;
function App() {
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState({});
  const params = useParams();
  const fetchRegUserById = async () => {
    try {
      const { data } = await API.get(`/users/fetchRegUserById/${params._id}`);
      setUser(data.user);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (params?._id) fetchRegUserById();
  }, [params?._id]);

  useEffect(() => {
    const loadScripts = async () => {
      const [jqueryResponse, mainResponse] = await Promise.all([
        fetch("./assets/js/jquery-3.3.1.min.js"),
        fetch("./assets/js/bootstrap.min.js"),
        fetch("./assets/js/popper.min.js"),
        fetch("./assets/js/main.js")
      ]);

      const [jqueryText, mainText] = await Promise.all([
        jqueryResponse.text(),
        mainResponse.text()
      ]);
      const jqueryScript = document.createElement("script");
      jqueryScript.text = jqueryText;
      document.body.appendChild(jqueryScript);
      const mainScript = document.createElement("script");
      mainScript.text = mainText;
      document.body.appendChild(mainScript);
    };
    loadScripts();
    return () => {
      // Cleanup code remains the same
    };
  }, []);

  return (
    <div className="container-fluid bg-white p-0 App">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About-Us" element={<AboutUs />} />
          <Route path="/About-Us/Our-Mission" element={<OurMission />} />
          <Route path="/About-Us/Our-Vision" element={<OurVision />} />
          <Route path="/Services" element={<OurCoreServices />} />
          <Route
            path="/Services/IT-Infrastructure"
            element={<ITInfrastructure />}
          />
          <Route path="/Behavioral-Health" element={<BehavioralHealth />} />
          <Route path="/Education" element={<Education />} />
          <Route
            path="/Health-Billing-&-Collections"
            element={<HealthBillingCollection />}
          />
          <Route
            path="/Healthcare-M-&-A"
            element={<HealthcareMergersAndAcquisitions />}
          />
          <Route
            path="/Recruiting-&-Leadership"
            element={<RecruitingAndLeadership />}
          />
          <Route
            path="/Behavioral-Health-Credentialing-&-Contracting"
            element={<BehavioralHealthCredentialingAndContracting />}
          />
          <Route
            path="/Web-Development-Services"
            element={<WebDevelopmentServices />}
          />
          <Route path="/Data-Analytics" element={<DataAnalytics />} />
          <Route path="/Higher-Education" element={<HigherEducation />} />
          <Route
            path="/Education-Counselling"
            element={<EducationCounselling />}
          />
          <Route path="/Infrastructure" element={<Infrastructure />} />
          <Route path="/Employers" element={<Employers />} />

          <Route path="/Search-Jobs" element={<BrowseJobs />} />
          <Route path="/Non-IT-Jobs" element={<NonITJobs />} />
          <Route path="/Search" element={<SearchJobFilter />} />
          <Route path="/Job-Details/:slug" exact element={<JobDetails />} />
          <Route
            path="/Non-IT-Job-Details/:slug"
            exact
            element={<NonITJobDetails />}
          />

          <Route path="/Contact-Us" element={<ContactUs />} />
          <Route path="/Thank-You-For-Contacting-Us" element={<ThankYou />} />

          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route
            path="/user-verify-account/:id/:token"
            element={<EmailVerification />}
          />
          <Route path="/Account/Forgot-Password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:id/:resetToken"
            element={<ResetPassword />}
          />

          <Route path="/" element={<AdminRoute />}>
            <Route path="/Admin/Dashboard" element={<AdminDashboardHome />} />
            <Route
              path="/Admin/Countries/Add-Country"
              element={<AddCountry />}
            />
            <Route
              path="/Admin/Countries/Manage-Countries"
              element={<ManageCountries />}
            />
            <Route path="/Admin/Salaries/Add-Salary" element={<AddSalary />} />
            <Route
              path="/Admin/Salaries/Manage-Salaries"
              element={<ManageSalaries />}
            />
            <Route
              path="/Admin/Provinces/Add-Province"
              element={<AddProvince />}
            />
            <Route
              path="/Admin/Provinces/Manage-Provinces"
              element={<ManageProvinces />}
            />
            <Route path="/Admin/Sectors/Add-Sector" element={<AddSector />} />
            <Route
              path="/Admin/Sectors/Manage-Sectors"
              element={<ManageSectors />}
            />
            <Route
              path="/Admin/Qualifications/Add-Qualification"
              element={<AddQualification />}
            />
            <Route
              path="/Admin/Qualifications/Manage-Qualifications"
              element={<ManageQualifications />}
            />
            <Route
              path="/Admin/WorkExperiences/Add-Work-Experience"
              element={<AddWorkExperience />}
            />
            <Route
              path="/Admin/WorkExperiences/Manage-Work-Experiences"
              element={<ManageWorkExperiences />}
            />
            <Route
              path="/Admin/WorkModes/Add-Work-Mode"
              element={<AddWorkMode />}
            />
            <Route
              path="/Admin/WorkModes/Manage-Work-Modes"
              element={<ManageWorkModes />}
            />
            <Route
              path="/Admin/WorkAuthorizations/Add-Work-Authorization"
              element={<AddWorkAuthorization />}
            />
            <Route
              path="/Admin/WorkAuthorizations/Manage-Work-Authorizations"
              element={<ManageWorkAuthorizations />}
            />
            <Route path="/Admin/Jobs/Post-Job" element={<PostJob />} />
            <Route
              path="/Admin/Jobs/Post-Non-IT-Job"
              element={<PostNonITJob />}
            />
            <Route path="/Admin/Jobs/Manage-Jobs" element={<ManageJobs />} />
            <Route
              path="/Admin/Jobs/Manage-Non-IT-Jobs"
              element={<ManageNonITJobs />}
            />
            <Route
              path="/Admin/Jobs/Delete-Job/:slug"
              element={<DeleteJob />}
            />
            <Route
              path="/Admin/Jobs/Update-Job/:slug"
              element={<UpdateJob />}
            />
            <Route
              path="/Admin/Contact-Messages/Manage-Contact-Us-Messages"
              element={<ManageContactMessages />}
            />
            <Route
              path="/Admin/Contact-Messages/View-Message/:id"
              element={<ViewContactMessage />}
            />
            <Route
              path="/Admin/Contact-Messages/Delete-Message/:id"
              element={<DeleteContactMessage />}
            />
            <Route
              path="/Account/Change-Password"
              element={<AdminChangePassword />}
            />
            <Route path="/Admin/Subscribers" element={<Subscribers />} />
          </Route>

          <Route path="/" element={<ApplicantRoute />}>
            <Route
              path="/Applicant/Dashboard"
              element={<ApplicantDashboardHome />}
            />
            <Route path="/Account/Apply-Job/:slug" element={<ApplyJobForm />} />
            <Route
              path="/Account/Apply-Non-IT-Job/:slug"
              element={<ApplyNonITJobForm />}
            />
            <Route
              path={`/Applicant/Profile/${auth?.user?.userId}`}
              element={<ApplicantProfile />}
            />
            <Route
              path="/Applicant/Jobs/Applied-Jobs"
              element={<ApplicantAppliedJobs />}
            />
            <Route
              path="/Applicant/Change-Password"
              element={<ApplicantChangePassword />}
            />
            <Route
              path={`/Resume-Upload/${auth?.user?.userId}`}
              element={<ResumeUpload />}
            />
            <Route
              path="/Applicant/Delete-Education/:id"
              element={<DeleteEducation />}
            />
            <Route
              path="/Applicant/Delete-Applicant-Experience/:id"
              element={<DeleteApplicantExperience />}
            />
            <Route
              path="/Applicant/Delete-Skill/:id"
              element={<DeleteSkill />}
            />
            <Route
              path="/Applicant/Delete-Resume/:id"
              element={<DeleteResume />}
            />
          </Route>
          <Route path="/Thinkbeyond-Access-Denied" element={<UnAuthorized />} />
          <Route path="/Unsubscribe" element={<Unsubscribe />} />
        </Routes>
      </Suspense>
      <StyledContainer />
    </div>
  );
}

export default App;
